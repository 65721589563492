.header-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 35px 0;
	.divider {
		flex-grow: 1;
		height: 1px;
		background: var(--base-clr);
		margin: 0 36px;
	}
	.logo {
		max-width: 160px;
		margin-right: auto;
		a {
			display: block;
			img {
				max-width: 100%;
			}
		}
	}
	.twitter-button {
		text-transform: uppercase;
		color: var(--base-clr);
		text-decoration: none;
		font-weight: 700;
		font-size: 14px;
		line-height: 1;
		display: flex;
		align-items: center;
		margin-left: 21px;
		padding-left: 21px;
		border-left: 1px solid var(--base-clr);
		svg {
			font-size: 18px;
			margin-right: 5px;
		}
	}

	@include breakpoint(max-md) {
		padding: 23px 0;
		.cmn--btn {
			font-size: 14px;
			min-width: auto;
			padding: 9px 18px;
			line-height: 1.5;
		}
	}
	@include breakpoint(max-sm) {
		.cmn--btn {
			font-size: 12px;
			min-width: auto;
			padding: 8px 15px;
			line-height: 1.5;
		}
		.logo {
			max-width: 150px;
		}
		.twitter-button {
			font-size: 12px;
			padding-left: 10px;
			margin-left: 10px;
			span {
				display: none;
			}
		}
		@media screen and (max-width: 350px) {
			.logo {
				max-width: 120px;
			}
		}
	}
}
