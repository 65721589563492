.banner-wrapper {
	display: flex;
	flex-wrap: wrap-reverse;
	justify-content: space-between;
	.banner-left {
		max-width: 559px;
		width: 100%;
		p {
			max-width: 470px;
		}
		.subtitle {
			text-transform: uppercase;
			letter-spacing: 3px;
			margin: 0;
			margin-bottom: 22px;
		}
		.title {
			font-weight: 400;
			letter-spacing: -2px;
			margin: 0;
			margin-bottom: 15px;
		}
		p {
			margin: 0;
			margin-bottom: 25px;
		}
		margin-right: 98px;
		@include breakpoint(max-sm) {
			.subtitle {
				font-size: 19px;
				letter-spacing: 0;
				margin-bottom: 12px;
			}
			@media screen and (min-width: 330px) {
				.title {
					font-size: 56px;
					line-height: 57px;
					letter-spacing: -2px;
				}
			}
		}
	}
	.banner-right {
		width: calc(100% - 559px - 98px);
		position: relative;
		.anime-elem {
			position: absolute;
			&.elem-1 {
				left: 45px;
				top: 25px;
				animation: zoomAnime 2s linear infinite;
				-moz-animation: zoomAnime 2s linear infinite;
				-webkit-animation: zoomAnime 2s linear infinite;
			}
			&.elem-2 {
				left: -48px;
				bottom: 66px;
				animation: zoomAnime 2s linear infinite;
				-moz-animation: zoomAnime 2s linear infinite;
				-webkit-animation: zoomAnime 2s linear infinite;
			}
			&.elem-3 {
				left: 20px;
				bottom: 39px;
				animation: rotateAnime 4s linear infinite;
				-moz-animation: rotateAnime 4s linear infinite;
				-webkit-animation: rotateAnime 4s linear infinite;
			}
			&.elem-4 {
				right: -60px;
				bottom: 39px;
				animation: zoomAnime 2s linear infinite;
				-moz-animation: zoomAnime 2s linear infinite;
				-webkit-animation: zoomAnime 2s linear infinite;
			}
			&.elem-5 {
				right: -100px;
				bottom: 100px;
				animation: rotateAnime 4s linear infinite;
				-moz-animation: rotateAnime 4s linear infinite;
				-webkit-animation: rotateAnime 4s linear infinite;
			}
		}
	}
	padding: 67px 0 66px;
	@include breakpoint(max-xxl) {
		align-items: center;
		.banner-left {
			margin-right: 30px;
		}
		.banner-right {
			width: calc(100% - 559px - 98px);
		}
	}
	@include breakpoint(max-xl) {
		.banner-left {
			margin-right: 30px;
			max-width: 500px;
		}
		.banner-right {
			width: calc(100% - 500px - 30px);
		}
		@include breakpoint(lg) {
			.banner-left {
				.subtitle {
					margin-bottom: 12px;
				}
				.title {
					font-size: 56px;
					line-height: 1.2;
				}
				p {
					font-size: 18px;
				}
			}
		}
	}
	@include breakpoint(max-lg) {
		.banner-left {
			margin-right: 0;
			max-width: 100%;
		}
		.banner-right {
			width: 100%;
		}
		padding: 0 0 66px;
	}
}
.banner-thumb {
	width: 100%;
	min-width: 550px;
	height: 450px;
	position: relative;
	&::before {
		background: var(--base-clr);
		position: absolute;
		inset: 0;
		inset: 5px -10px -4px 10px;
		content: "";
		clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
		transform: translate(4px, 3px);
		margin-top: -4px;
	}
	.bg-thumb {
		inset: 0;
		position: absolute;
		clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.main-thumb {
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%, -50%));
		z-index: 1;
		max-width: 271px;
		img {
			width: 100%;
		}
	}
	@include breakpoint(max-lg) {
		margin-bottom: 45px;
		min-width: 0;
		&::before {
			display: none;
		}
		height: 350px;
		.main-thumb {
			height: 180px;
			@include breakpoint(max-md) {
				height: 125px;
			}
			width: 100%;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.bg-thumb {
			clip-path: unset;
			position: absolute;
			img {
				width: 100% !important;
				height: 100% !important;
				object-fit: cover;
				object-position: center center;
			}
		}
		.main-thumb {
			width: unset;
		}
		@include breakpoint(max-md) {
			height: 277px;
		}
	}
}
.banner-section {
	position: relative;
	overflow: hidden;
}
.banner-bottom-divider {
	max-width: 1091px;
	height: 1px;
}
@keyframes rotateAnime {
	0% {
		@include transform(rotate(0deg));
	}
	50% {
		@include transform(rotate(-180deg));
	}
	100% {
		@include transform(rotate(0deg));
	}
}
@keyframes zoomAnime {
	0% {
		@include transform(scale(1));
	}
	50% {
		@include transform(scale(1.2));
	}
	100% {
		@include transform(scale(1));
	}
}
