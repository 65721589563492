$bootstrap-sass-asset-helper: false !default;

$success-color: #0ead69;
$secondary-color: #2f3e46;
$danger-color: #d90429;
$warning-color: #ee9b00;
$info-color: #00b4d8;
$primary-color: #0c67a3;

$ash-color: #fafafa;
$white: #fff;
$lallu-color: #e883ae;
$dark-color: #192a56;
$dark-bg: #131b32;
$light-color: #daddff;
$black: #000;
$section-bg-2: #fafafa;
$whatsapp: #25d366;
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb5;
$pinterest: #007bb5;
$skype: #2fb7df;
$youtube: #cd201f;
$google: #dd4b39;
$camera: #e4405f;
$instagram: #e4405f;
$tumblr: #34465d;
$behance: #1769ff;
$star: #e9ba17;
$green: #0be881;
$green-h: #0fbcf9;
$red: #f53b57;
$yellow: #dec32b;
$nell: #212fb4;
$navy: #d8e0f3;
$placeholder: #a1a1a2;
$name-dec: #3f485d;
$futbol: #db3776;
$vimeo: #1ab7ea;
$dribble: #ea4c89;

$transition: all ease 0.3s;

:root {
	--base-clr: #0936a1;
	--base-rgb: 213, 170, 83;
	--base-clr-2: #b2ff9e;
	--base-rgb: 178, 255, 158;
	--body-clr: #536479;
	--title-clr: #141515;
	--title-rgb: 20, 21, 21;
	--section-bg: #f4f7fa;
	--body-bg: #ffffff;
	--dark-clr: #043448;
	--dark-rgb: 61, 6, 95;
	--white-clr: #ffffff;
	--white-rgb: 255, 255, 255;
	--light-clr: #f7f6ff;
	--primary-clr: #0c67a3;
	--primary-rgb: 12, 103, 163;
	--border-clr: #e5e5e5;
	--border-dark: #283c40;
	--border-dark-only: transparent;
	--shadow: 0 0 5px rgba(var(--title-rgb), 0.2);
	--heading-fonts: "Roboto", sans-serif;
	--body-fonts: "Roboto", sans-serif;
	--base-shadow-sm: 0 0 1px var(--base-clr), 0 0 3px var(--base-clr);
	--base-shadow: 0 0 1px var(--base-clr), 0 0 2px var(--base-clr),
		0 0 3px var(--base-clr), 0 0 5px var(--base-clr), 0 0 7px var(--base-clr);
}
.text--base {
	color: var(--base-clr) !important;
}
.bg--base {
	background: var(--base-clr) !important;
}
.text--title {
	color: var(--title-clr) !important;
}
.bg--title {
	background: var(--title-clr) !important;
}
.text--base-2 {
	color: var(--base-clr-2) !important;
}
.bg--base-2 {
	background: var(--base-clr-2) !important;
}
