.event-info-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 50px 0 20px;
	.event-info-item {
		display: flex;
		align-items: center;
		width: calc(100% / 3);
		.subtitle {
			margin: 0;
		}
		.event-icon {
			width: 32px;
			img {
				max-width: 100%;
			}
		}
		.subtitle {
			padding-left: 40px;
			line-height: 23px;
			font-weight: 600;
			width: calc(100% - 32px);
		}
		&:nth-child(2) {
			max-width: 230px;
		}

		margin-bottom: 30px;
		@include breakpoint(max-xl) {
			@include breakpoint(lg) {
				.subtitle {
					font-size: 18px;
				}
			}
		}
		@include breakpoint(max-lg) {
			width: 100%;
			max-width: 100% !important;
			margin-left: 30px;
			margin-right: 30px;
			@include breakpoint(md) {
				margin-left: 50px;
			}
		}
		@media (max-width: 425px) {
			max-width: 250px !important;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.polygonshape-shape {
	position: absolute;
	left: calc(50% + 530px);
	top: 60px;
	transform: rotate(-4deg);
}
.section-title {
	margin-bottom: 40px;
	@include breakpoint(lg) {
		margin-bottom: 50px;
	}
}
.section-title-2 {
	margin-top: 40px;
	margin-bottom: 40px;
	@include breakpoint(lg) {
		margin-bottom: 50px;
	}
}
.event-section {
	padding: 85px 0 55px;
}
.custom-container {
	@include breakpoint(xl) {
		max-width: 1306px;
	}
}
.event-item {
	background: rgba(#072b81, 0.6);
	border: 1px solid rgba(#b2ff9e, 0.6);
	border-radius: 8px;
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.event-name {
		@media (min-width: 600px) {
			min-height: 96px;
		}
	}
	.title {
		margin: 0;
		margin-bottom: 31px;
	}
	.subtitle {
		max-width: 320px;
		margin: auto auto 0;
		margin-bottom: 18px;
		text-transform: uppercase;
		letter-spacing: 3px;
	}
	.date {
		margin-bottom: 30px;
	}
	padding: 26px 20px 44px;
	img {
		width: 222px;
		height: 154px;
		object-fit: contain;
		margin-bottom: 36px;
	}
	.cmn--btn {
		background: var(--base-clr-2);
		color: var(--base-clr);
		&:hover,
		&:focus {
			border-color: var(--base-clr-2);
			color: var(--base-clr-2);
		}
	}
	@include breakpoint(max-xxl) {
		@include breakpoint(xl) {
			.title {
				font-size: 32px;
				line-height: 1.3;
				margin-bottom: 25px;
			}
			img {
				height: 130px;
			}
			.subtitle {
				margin-bottom: 15px;
				letter-spacing: 1px;
			}
			.date {
				margin-bottom: 25px;
			}
			h5 {
				line-height: 1.3;
				font-size: 18px;
				font-weight: 400;
			}
		}
	}
	@include breakpoint(max-xl) {
		@include breakpoint(lg) {
			.title {
				font-size: 24px;
				line-height: 1.3;
				margin-bottom: 25px;
			}
			img {
				height: 100px;
			}
			.subtitle {
				margin-bottom: 15px;
				letter-spacing: 1px;
			}
			.date {
				margin-bottom: 25px;
			}
			h5 {
				line-height: 1.3;
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
	@include breakpoint(max-md) {
		max-width: 265px;
		margin-left: auto;
		margin-right: auto;
		.title {
			font-size: 24px;
			line-height: 1.3;
			margin-bottom: 25px;
		}
		img {
			height: 94px;
			margin-bottom: 20px;
		}
		.subtitle {
			margin-bottom: 15px;
			letter-spacing: 1px;
		}
		.date {
			margin-bottom: 25px;
		}
		h5 {
			line-height: 1.3;
			font-size: 16px;
			font-weight: 400;
		}
	}
	@include breakpoint(max-sm) {
		max-width: 265px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 30px;
		.title {
			font-size: 26px;
			line-height: 1.3;
			margin-bottom: 25px;
		}
		img {
			max-width: 167px;
			height: 94px;
			order: -2;
		}
		.subtitle {
			margin-bottom: 15px;
			letter-spacing: 1px;
			order: -1;
		}
		.date {
			margin-bottom: 25px;
		}
		h5 {
			line-height: 1.3;
			font-size: 17px;
			font-weight: 400;
		}
	}
}
.event-bottom {
	max-width: 962px;
	margin: 65px auto 0;
	.text {
		margin-bottom: 40px;
	}
	.bottom-img {
		max-width: 88px;
	}
}
.cta-content {
	max-width: 550px;
	margin: 0 auto;
	p {
		max-width: 385px;
		margin: 0 auto 31px;
	}
	.title {
		margin-bottom: 18px;
	}
}
.cta-button-container {
	display: grid;
	place-items: center;
}
.cta-section {
	padding: 113px 0;
	@include breakpoint(max-md) {
		padding: 67px 0;
	}
}
.cta-oval {
	position: absolute;
	left: 0;
	top: 90px;
	@include breakpoint(max-lg) {
		display: none;
	}
}
.cta-anime {
	animation: zoomAnime 2s linear infinite;
	-moz-animation: zoomAnime 2s linear infinite;
	-webkit-animation: zoomAnime 2s linear infinite;
	&.anime-1 {
		left: calc(50% + 500px);
		bottom: 80px;
		@include breakpoint(max-lg) {
			left: calc(50% + 300px);
		}
	}
	&.anime-2 {
		left: calc(50% + 438px);
		bottom: 170px;
		@include breakpoint(max-lg) {
			left: calc(50% + 268px);
		}
	}
	position: absolute;
	@include breakpoint(max-md) {
		display: none;
	}
}
.header-content {
	text-align: center;
	.cate {
		letter-spacing: 3px;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
	.title {
		margin: 0;
		margin-bottom: 18px;
	}
	p {
		margin: 0;
	}
	max-width: 570px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 35px;
	@include breakpoint(lg) {
		margin-bottom: 46px;
	}
}
.sponsor-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	max-width: 860px;
	margin: 0 auto -30px;
	.sponsor-img {
		width:160px;
		height: 120px;
		padding: 0 16px 16px;
		text-align: center;
		img {
			max-height: 50px;
			max-width: 100%;

			justify-content: center;
		}
		max-width: calc(100% / 4);
		@include breakpoint(max-sm) {
			max-width: calc(100% / 2);
			width: 50%;
			text-align: center;
			img {
				max-width: 100%;
				max-height: 56px;
				object-fit: contain;
				justify-content: center;
			}
			&:first-child {
				img {
					max-height: 80px;
				}
			}
		}
	}
}
.sponsor-section {
	padding: 10px 0 100px;
	position: relative;
	@include breakpoint(max-md) {
		padding-bottom: 35px;
	}
}
.sponsor_bottom_shape {
	position: absolute;
	bottom: 0;
	right: 0;
	img {
		max-width: 100%;
	}
}

.co-organizers-section {
	padding: 10px 0 50px;
	position: relative;
	@include breakpoint(max-md) {
		padding-bottom: 35px;
	}
}

.co-organizers {
	padding-top: 24px;
}
.co-organizers-wrapper {
	display: flex;
	flex-wrap: wrap;
	max-width: 660px;
	justify-content: center;
	margin: 0 auto;
	align-items: center;
	.co-sponsor-img {
		width: calc(100% / 2);
		padding: 0 20px 45px;
		text-align: center;
		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}
		@include breakpoint(max-sm) {
			width: 100%;
			padding-bottom: 30px;
			text-align: center;
			img {
				max-width: 196px;
			}
		}
	}
}
.cta-mobile-shape {
	position: absolute;
	right: 0;
	bottom: 0;
}
.upcoming-events-section {
	padding-bottom: 80px;
	position: relative;
	@include breakpoint(lg) {
		padding-bottom: 120px;
	}
}
.upcoming-events {
	p {
		max-width: 390px;
		margin-left: auto;
		margin-right: auto;
	}
}
.input-wrapper {
	margin: 0 auto;
	max-width: 391px;
	text-align: center;
	.form--control {
		margin-top: 7px;
	}
	.cmn--btn {
		margin-top: 28px;
		min-width: 176px;
	}
}
.form--control {
	height: 60px;
	border-radius: 5px;
	background: #ececec;
	box-shadow: none !important;
	text-align: center;
}
