.cmn--btn {
	font-weight: 700;
	text-transform: uppercase;
	outline: none;
	border: none;
	padding: 10px 28px;
	overflow: hidden;
	background: var(--base-clr);
	color: var(--base-clr-2);
	text-decoration: none;
	text-align: center;
	font-family: var(--heading-fonts);
	position: relative;
	border-radius: 4px;
	width: auto;
	border: 1px solid var(--base-clr);
	min-width: 240px;
	font-size: 16px;
	line-height: 26px;
	&:hover,
	&:focus {
		color: var(--base-clr);
		border-color: var(--base-clr);
		background: transparent;
	}
	&.btn-outline {
		color: var(--base-clr);
		background: transparent;
		&:hover,
		&:focus {
			background: var(--base-clr);
			color: var(--base-clr-2);
		}
	}
}

.cmn--btn-icon {
	font-weight: 700;
	text-transform: uppercase;
	outline: none;
	border: none;
	padding: 10px 28px;
	margin-top: 10px;
	overflow: hidden;
	background: var(--base-clr);
	color: var(--base-clr-2);
	text-decoration: none;
	text-align: center;
	font-family: var(--heading-fonts);
	position: relative;
	border-radius: 4px;
	width: auto;
	border: 1px solid var(--base-clr);
	min-width: 240px;
	font-size: 16px;
	line-height: 26px;
	&:hover,
	&:focus {
		color: var(--base-clr);
		border-color: var(--base-clr);
		background: transparent;
	}
	&.btn-outline {
		color: var(--base-clr);
		background: transparent;
		&:hover,
		&:focus {
			background: var(--base-clr);
			color: var(--base-clr-2);
		}
	}
}

.telegram-icon {
	filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
	width: 24px;
	float: left;
	margin-right: 6px;
	margin-top: 0px;
}
