.footer-menus {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 268px;
	li {
		a {
			text-decoration: none;
			font-size: 16px;
			line-height: 1.5;
			color: var(--title-clr);
			&:hover {
				color: var(--base-clr);
			}
		}
	}
	ul {
		padding: 0 12px;
	}
	width: 100%;
	margin-bottom: 30px;
	@include breakpoint(md) {
		margin-left: 40px;
		margin-right: auto;
	}
}
.footer-top-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 -12px;
	.footer-logo {
		margin: 8px 12px 30px;
		a {
			display: inline-block;
		}
		img {
			max-width: 100%;
		}
		@include breakpoint(md) {
			max-width: 180px;
		}
	}
}
.social-icons {
	@extend %flex;
	li {
		padding: 10px;
		a {
			width: 36px;
			height: 36px;
			line-height: 36px;
			color: var(--base-clr-2);
			background: var(--base-clr);
			text-align: center;
			font-size: 18px;
		}
	}
}
.social-area {
	padding: 0 12px 30px;
}
@include breakpoint(max-md) {
	.social-area,
	.footer-logo,
	.footer-menus {
		width: 100%;
		text-align: center;
		ul {
			width: 100%;
		}
	}
	.footer-menus {
		margin-left: auto;
		margin-right: auto;
	}
	.social-icons {
		justify-content: center;
	}
}

.footer-top {
	position: relative;
	padding: 106px 0 50px;
	border-top: 1px solid rgba(var(--title-rgb), 0.2);
	border-bottom: 1px solid rgba(var(--title-rgb), 0.2);
	@include breakpoint(max-md) {
		padding-top: 60px;
		padding-bottom: 25px;
	}
}
.footer-oval {
	position: absolute;
	right: 0;
	bottom: 0;
	@include breakpoint(max-md) {
		right: -80px;
		bottom: 45px;
		img {
			max-width: 120px;
		}
	}
}
.footer-shape {
	position: absolute;
	left: calc(50% + 180px);
	bottom: 80px;
	animation: rotateAnime 4s linear infinite;
	-moz-animation: rotateAnime 4s linear infinite;
	-webkit-animation: rotateAnime 4s linear infinite;
	@include breakpoint(max-lg) {
		display: none;
	}
}
.footer-bottom-shape {
	position: absolute;
	left: 0;
	bottom: 0;
	@include breakpoint(max-lg) {
		img {
			max-width: 50px;
		}
	}
}
footer {
	position: relative;
	overflow: hidden;
}
.footer-bottom-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 43px 0 12px;
	font-size: 16px;
	line-height: 1.5;
	color: var(--body-clr);
	margin: 0 -17px;
	.copyright {
		margin: 0 17px;
		@include breakpoint(max-sm) {
			max-width: 160px;
			text-align: center;
		}
	}
	.copyright,
	.quick-links {
		margin-bottom: 20px;
	}
	.quick-links {
		@extend %flex;
		justify-content: center;
		li {
			padding: 0 12px;
			@include breakpoint(sm) {
				&:not(:last-child) {
					margin-right: 20px;
				}
			}
			a {
				padding: 5px;
				text-decoration: none;
				color: var(--title-clr);
			}
		}
	}
	@include breakpoint(max-md) {
		justify-content: space-evenly;
		padding-bottom: 35px;
	}
}
.footer-mobile-shape {
	position: absolute;
	top: 70px;
	left: 0;
	animation: zoomAnime 1s linear infinite;
	-moz-animation: zoomAnime 1s linear infinite;
	-webkit-animation: zoomAnime 1s linear infinite;
}
